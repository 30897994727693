import { Crosshair } from "@phosphor-icons/react/dist/ssr/Crosshair";
import type { MouseEventHandler } from "react";
import { type FC, memo } from "react";
import { GhostButton } from "../../button/ghost-button";
import { LoadingIndicator } from "../../loading/loading-indicator";


type Props = {
  onClick?: MouseEventHandler<HTMLElement>,
  className?: string;
  loading?: boolean;
}

const GeolocationButton: FC<Props> = memo(function(props) {
  return <GhostButton onClick={props.onClick} className={`text-center text-2xl ${props.className} h-full w-[56px]`} disabled={props.loading} attrs={{type: "button"}}>
    {props.loading 
      ? <LoadingIndicator className="!m-0 aspect-square !text-brand" />
      : <Crosshair weight="bold" />}
  </GhostButton>
});

GeolocationButton.displayName = 'GeolocationButton';
export { GeolocationButton };