import { useCallback } from "react";
import type { ApiGouvAutocompleteResult, GeolocationPosition } from "@/components/store-locator/types";
import { sendGTMEvent } from "@next/third-parties/google";

/**
 * Allow tu convert GPS coords into a city 
 * @returns 
 */
export function useGeoReverse() {
  return useCallback(async (pos: GeolocationPosition) => {
    const query = new URLSearchParams({
      lon: pos.gpsLng.toString(),
      lat: pos.gpsLat.toString(),
      type: 'municipality'
    });

    const [response, decoded] = await fetch(`https://api-adresse.data.gouv.fr/reverse/?${query}`)
      .then(async res => [res, await res.json()]);

    if (!response.ok) {
      sendGTMEvent({
        event: 'search_erreur_API',
        search_term: pos,
        response: decoded
      });
      return null;
    }

    return decoded.features?.[0] as ApiGouvAutocompleteResult ?? null;
  }, []);
}