import type { ApiGouvAutocompleteResult } from "@/components/store-locator/types";
import { sendGTMEvent } from "@next/third-parties/google";
import { useCallback } from "react";

export function useGeoAutocomplete() {
  return useCallback(async (search: string) => {
    if (!search) return [];
  
    const query = new URLSearchParams({
      autocomplete: '1',
      q: search,
      type: 'municipality',
    }).toString();
  
    try {
      const [response, decoded] = await fetch(
        `https://api-adresse.data.gouv.fr/search/?${query}`,
      ).then(async res => [res, await res.json()]);
      
      if (!response.ok) {
        sendGTMEvent({
          event: 'search_erreur_API',
          search_term: search,
          response: decoded
        });
        throw 'Errreur';
      }
  
      return decoded.features as ApiGouvAutocompleteResult[];
    } catch (e) {
      return [];
    }
  }, []);
}