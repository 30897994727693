import { useCallback } from "react";
import type { GeolocationPosition } from "@/components/store-locator/types";
import { captureError } from "@/utils/error-utils";


export function useGeoLocate() {
  return useCallback(() => {
    return new Promise<GeolocationPosition>((resolve, reject) => {
      if (typeof navigator === "undefined" || !("geolocation" in navigator))
        return reject("Geolocation is not supported by the navigator");
  
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            gpsLat: position.coords.latitude,
            gpsLng: position.coords.longitude
          })
        },
        (error) => {
          switch (error.code) {
            // Refuse geolocation
            case 1 :
              return reject("Vous avez refusé la géolocalisation de votre position par votre navigateur");

            // Unknown Error
            default: 
              captureError(error);
              return reject("Une erreur inattendue est survenue");
          }
        }
      );

    });
  }, []);
}