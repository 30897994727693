'use client';

import { FilledButton } from '@/components/button/filled-button';
import { Combobox, Transition } from '@headlessui/react';
import { CaretUpDown, MagnifyingGlass } from '@phosphor-icons/react/dist/ssr';
import type { ChangeEventHandler } from 'react';
import { useCallback, useEffect, useState, type FC } from 'react';
import type { ApiGouvAutocompleteResult } from '../types';
import { GeolocationButton } from './geolocation-button-v2';
import { useGeoLocate } from '@/lib/hooks/use-geo-locate';
import { useGeoReverse } from '@/lib/hooks/use-geo-reverse';
import { useGeoAutocomplete } from '@/lib/hooks/use-geo-autocomplete';
import {
  MAX_DISTANCE,
  MIN_DISTANCE,
  type StoreLocatorQuery,
} from '@/utils/stores/use-store-locator';
import { StoreLocatorFormAutocompleteResult } from './store-locator-form-autocomplete-result';
import { StoreLocatorUtils } from '@/utils/store-locator-utils';
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import toast from 'react-hot-toast';
import { Notice } from '@/components/notices/notice';

type Props = {
  onChange: (query: StoreLocatorQuery) => void;
  isLoading?: boolean;
  initialValues?: StoreLocatorQuery;
  className?: string;
};

const MIN_AUTOCOMPLETION_LENGHT = 2;

const schema = z.object({
  search: z.string().min(3),
  distance: z.coerce.number().min(MIN_DISTANCE).max(MAX_DISTANCE),
});

type FormValues = z.infer<typeof schema>;

export const StoreLocatorSearchForm: FC<Props> = function ({
  onChange,
  initialValues,
  isLoading = false,
  className = '',
}) {
  const [loading, setLoading] = useState(isLoading);
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);
  const [results, setResults] = useState<ApiGouvAutocompleteResult[] | null>(
    null,
  );
  const [selected, setSelected] = useState<ApiGouvAutocompleteResult | null>(
    null,
  );

  // The form validation object
  const form = useForm<FormValues>({
    initialValues: {
      search: initialValues?.search ?? '',
      distance: initialValues?.distance ?? 50,
    },
    validate: zodResolver(schema),
    transformValues: (values) => ({
      ...values,
      distance: Number(values.distance),
    }),
  });

  // Called when form is submited by clicking submit button or Enter in the distance field
  const handleSubmit = useCallback(
    (values: FormValues) => {
      onChange({
        search: values.search,
        distance: values.distance,
      });
    },
    [onChange],
  );

  // Displays the errors forms in a toast
  useEffect(() => {
    toast.remove();

    if (form.errors['search'])
      toast.custom(
        <Notice
          type="ERROR"
          message={`Recherche : ${form.errors['search']}`}
        />,
      );

    if (form.errors['distance'])
      toast.custom(
        <Notice type="ERROR" message={`Rayon : ${form.errors['distance']}`} />,
      );
  }, [form.errors]);

  // User Geolocation
  const geoReverse = useGeoReverse();
  const geolocate = useGeoLocate();
  const handleGeolocation = useCallback(async () => {
    setLoading(true);

    try {
      const position = await geolocate();
      const result = await geoReverse(position);
      if (result) {
        setSelected(result);
        const search = StoreLocatorUtils.formatApiGouvResult(result).label;
        form.setValues({ search });

        onChange({
          distance: form.values['distance'],
          search,
          gpsLat: result.geometry.coordinates[1],
          gpsLng: result.geometry.coordinates[0],
        });
      }
    } catch (e) {
      toast.custom(<Notice type="ERROR" message={e as string} />, {
        duration: 5_000,
      });
    }

    setLoading(false);
  }, [form, geoReverse, geolocate, onChange]);

  // Place auto completion
  const geoAutocomplete = useGeoAutocomplete();

  // Initial search results fetch
  useEffect(() => {
    if (!initialValues?.search) return;

    setLoading(true);
    geoAutocomplete(initialValues.search)
      .then((results) => {
        setResults(results);
        setSelected(results[0]);
      })
      .finally(() => setLoading(false));
  }, [geoAutocomplete, initialValues?.search]);

  const query = form.values['search'];
  // Do the autocompletion when the user did not type into the input after x ms
  useEffect(() => {
    const debounceFn = setTimeout(async () => {
      if (query.length > MIN_AUTOCOMPLETION_LENGHT) {
        const res = await geoAutocomplete(query);
        setLoading(true);
        setResults(res);
        setLoading(false);
      }
    }, 300);

    return () => clearTimeout(debounceFn);
  }, [geoAutocomplete, query]);

  // Resets the results on the user input
  const handlePacInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    async (e) => {
      form.getInputProps('search').onChange(e);
      setResults(null);
    },
    [form],
  );

  // Triggered when the user pick a choice into the auto completion results
  const handlePacSelect = useCallback(
    (place: ApiGouvAutocompleteResult | null) => {
      setSelected(place);
      const search = StoreLocatorUtils.formatApiGouvResult(place).label;
      form.setValues({ search });
      onChange({
        distance: form.values['distance'],
        search,
        gpsLat: place?.geometry.coordinates[1],
        gpsLng: place?.geometry.coordinates[0],
      });
    },
    [form, onChange],
  );

  const pacComparison = useCallback(
    (
      a: ApiGouvAutocompleteResult | null,
      b: ApiGouvAutocompleteResult | null,
    ) => a?.properties.id === b?.properties.id,
    [],
  );

  const showResults =
    query.length > MIN_AUTOCOMPLETION_LENGHT && results !== null;

  return (
    <form className={`relative z-10 ${className}`} onSubmit={form.onSubmit(handleSubmit)}>
      <Combobox
        value={selected}
        onChange={handlePacSelect}
        by={pacComparison}
        disabled={loading}>
        {({ open }) => (
          <>
            {/* Input Row */}
            <div
              className={`relative flex flex-row justify-end rounded-xl border-2 border-grey-700  ${
                loading ? '!bg-gray-50 text-gray-400' : 'bg-white text-grey-700'
              }`}>
              {/* Search field */}
              <div className="relative flex-1 flex-col items-center pl-2 pt-2">
                <Combobox.Label className="-mb-0.5 block cursor-pointer text-left text-xs uppercase text-gray-400">
                  Recherche
                </Combobox.Label>
                <div className='flex items-start'>
                  <Combobox.Input
                    name="search"
                    type={'text'}
                    placeholder={'Ville, code postal...'}
                    className={
                      '-ml-2 block w-full truncate bg-transparent pb-2 pl-2 outline-none'
                    }
                    // {...form.getInputProps('search')}
                    readOnly={loading}
                    onChange={handlePacInput}
                    displayValue={(v: ApiGouvAutocompleteResult | undefined) =>
                      StoreLocatorUtils.formatApiGouvResult(v).label
                    }
                  />
                  <Combobox.Button
                    className={
                      'rounded-full p-1 transition-colors hover:bg-light-grey hover:text-brand active:bg-light-grey/50 disabled:!bg-transparent disabled:!text-inherit'
                    }>
                    <CaretUpDown weight="bold" />
                  </Combobox.Button>
                </div>
              </div>

              {/* Distance field */}
              <label className="relative z-10 flex cursor-pointer flex-col justify-center p-2 uppercase">
                <span className="-mb-0.5 block text-left text-xs text-gray-400">
                  Rayon
                </span>
                <div className="flex">
                  <input
                    name="distance"
                    type={'number'}
                    placeholder={'50...'}
                    className={
                      'w-[20px] bg-transparent outline-none lg:w-[30px]'
                    }
                    {...form.getInputProps('distance')}
                    disabled={loading}
                    readOnly={loading}
                  />
                  <span>KM</span>
                </div>
              </label>
              {/* /Distance Field */}

              {/* Actions */}
              <div className="z-10 flex flex-row items-center">
                {/* Geolocation */}
                <GeolocationButton
                  onClick={handleGeolocation}
                  loading={loading}
                  className="-mr-1 rounded-r-none hover:text-brand"
                />

                {/* Submit button */}
                <FilledButton
                  intent={'secondary'}
                  attrs={{ type: 'submit' }}
                  disabled={loading}>
                  <MagnifyingGlass
                    size={24}
                    weight={'bold'}
                    className={'block 2xl:hidden'}
                  />
                  <span className={'hidden 2xl:inline'}>Voir les tarifs</span>
                </FilledButton>
              </div>
              {/* /Actions */}
            </div>
            {/* /Input Row */}

            {/* Autocomplete results list */}
            <Transition
              show={showResults && open}
              unmount={false}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0">
              <Combobox.Options
                className={`${showResults ? '' : 'hidden'} absolute inset-x-0 top-full z-50 mt-4 divide-y-2 divide-light-grey overflow-hidden rounded-lg border-2 border-light-grey bg-white py-2 text-gray-700 shadow-2xl`}
                unmount={false}
                hold={false}>
                {results !== null && results?.length < 1 ? (
                  <div className="p-4 text-center text-sm text-gray-400">
                    Aucun résultat trouvé
                  </div>
                ) : (
                  results?.map((result) => (
                    <Combobox.Option key={result.properties.id} value={result}>
                      {({ active, selected }) => (
                        <StoreLocatorFormAutocompleteResult
                          result={result}
                          active={active}
                          selected={selected}
                        />
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
            {/* /Autocomplete results list */}
          </>
        )}
      </Combobox>
    </form>
  );
};
